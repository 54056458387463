<template>
	<div class="my-orders-detail">
		<div class="headerTop">

		</div>
		<div class="order-content">
			<!--  -->
			<div class="orderInfo">
				<div v-for="(item,index) in orderDetail.products" class="goods-item">
					<img :src="item.image" alt="">
					<div
						style="height: 80px;display: flex;justify-content: space-between;flex-direction: column;width: calc(100% - 105px);">
						<div class="detailTitle">{{item.name}}</div>
						<div class="countUnit">
							<p> <span style="font-size: 16px;color: red;"><span
										style="font-size: 12px;">￥</span>{{item.price}}</span> <span
									style="font-size: 12px;">{{item.unit}}</span>
							</p>
							<p>x{{item.count}}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="orderInfo">
				<div v-for="(item,index) in orderDetail.products">
					<div class="detailTitle">{{item.name}}</div>
					<div class="countUnit">
						<p> <span style="font-size: 18px;color: red;">￥{{item.price}}</span> <span>{{item.unit}}</span>
						</p>
						<p>x{{item.count}}</p>
					</div>
				</div>
			</div> -->
			<!--  -->
			<div class="numOrder">
				<div class="infoNum">订单信息</div>
				<div class="listInfo">
					<p>订单编号: <span>{{orderDetail.order_num}}</span></p>
					<!-- <p>购买数量: <span>{{orderDetail.count}}</span></p> -->
					<p>订单金额: <span>{{orderDetail.money}}</span></p>
					<p>订单状态:
						<span v-if="orderDetail.pay_status == 0">待支付</span>
						<span v-if="orderDetail.pay_status == 1">已支付</span>
						<span v-if="orderDetail.pay_status == 2">订单已取消</span>
						<span v-if="orderDetail.pay_status == -1">已退款</span>
					</p>
					<p>订单时间: <span>{{orderDetail.create_time}}</span></p>
				</div>
			</div>
			
			<div class="clientInfo">
				<div class="infoNum">客户信息</div>
				<div class="listInfo">
					<p>下单人姓名: <span>{{orderDetail.name}}</span></p>
					<p>下单人手机号: <span>{{orderDetail.tel}}</span></p>
				</div>
			</div>
			<div class="noticeInfo" v-if="orderDetail.qrcode != '' ">
				<div class="infoNum">订单核销码</div>
				<div  class="fkm" >
					<img :src="url" alt="" id="qrcode" ref="qrcode" @click="handleClick">
				</div>
				<!-- <el-button>解析在线二维码</el-button> -->
			</div>
		</div>
		<div v-if="orderDetail.pay_status == 2"></div>
		<div class="footerBott" v-else>
			<div>
				<button v-if="orderDetail.take_status == 0 && orderDetail.pay_status == 1" @click="getCheck">未核销</button>
				<button v-if="orderDetail.take_status == 1 && orderDetail.pay_status == 1">已核销</button>
				<button v-if="orderDetail.pay_status != 2" @click="cancelOrder(orderDetail.id)">取消订单</button>
				<button v-if="orderDetail.pay_status == 0" @click="getTN(orderDetail.order_num)">待支付</button>
				<button v-if="orderDetail.pay_status == 1">已支付</button>
			</div>
			<div>

			</div>
		</div>

	</div>
</template>

<script>
	import {
		getOrderDetails,
		cancelOrder,
		getTN,
		getQrcodeInfo,
		getCheck
	} from '@/api/order'

	import {
		ref
	} from 'vue';
	import {
		useRoute
	} from "vue-router";
	import QRCode from 'qrcodejs2';
	import QrCode from 'qrcode-decoder'
	import {
		Toast
	} from "vant";
	export default {
		setup() {

		},
		data() {
			return {
				orderDetail: {},
				downUrl: null,
				downFile: null,
				url: '',
				codeurl: '',
				code: ''
			}
		},

		mounted() {
			this.getOrderDetail()
			console.log(this.$route.query.id)
			console.log(window.localStorage.getItem('code'))
		},
		methods: {
			handleClick() {
				// var qr = new QrCode();
				// qr.decodeFromImage(this.url, {
				// 	crossOrigin: "anonymous"
				// }).then((res) => {
				// 	console.log(res.data)
				// 	this.codeurl = res.data
				// 	this.toQrcode()
				// 	window.localStorage.setItem('code', this.codeurl);
				// });
				var qr = new QrCode();
				let img = this.$refs['qrcode']
				console.log(img);
				qr.decodeFromImage(img, {
					crossOrigin: "anonymous"
				}).then((res) => {
					console.log(res.data)
					this.codeurl = res.data
					this.toQrcode()
					window.localStorage.setItem('code', this.codeurl);
				});

			},
			// /h5/toQrcode
			toQrcode() {
				this.$router.push({
					path: '/h5/toQrcode',
					query: {
						code: this.codeurl
					}
				})
			},
			getCheck() {
				let params = {}
				params.code = window.localStorage.getItem('code')
				getCheck(params).then((res) => {
					console.log(res)
					Toast(res.msg)
				})
			},
			qrcode(url) {
				// console.log(url)
				document.getElementById("qrcode").innerHTML = "";
				let qrcode = new QRCode("qrcode", {
					text: url,
					width: 130,
					height: 130,
					colorDark: "#000000",
					colorLight: "#ffffff",
					correctLevel: QRCode.CorrectLevel.H
				});
			},
			danBase(imgUrl) {
				let timeMap = new Date().getTime();
				let name = imgUrl.substring(22, 30) + timeMap + '.png';
				this.downUrl = imgUrl;
				this.downFile = name
				// console.log(this.downUrl, this.downFile)
				this.qrcode(this.downFile);
			},
			getOrderDetail() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.id = this.$route.query.id
				getOrderDetails(params).then((res) => {
					console.log(res)
					this.orderDetail = res.data
					console.log(res.data.qrcode)
					this.danBase(res.data.qrcode);
					this.url = res.data.qrcode
					// this.$toast.clear()
				})
			},
			getTN(num) {
				getTN({
					'order_num': num
				}).then((res) => {
					console.log(res)
					upsdk.pluginReady(function() {
						upsdk.pay({
							tn: res.data.tn,
							success: function() {
								// 支付成功, 开发者执行后续操作。
								console.log('成功')
								this.$toast('支付成功')
								setTimeout(() => {
									this.$router.push({
										path: '/h5/orderPage'
									})
								}, 1000)
							},
							fail: function(err) {
								// 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
								this.$toast(err.msg)
							}
						});
					});
				})
			},
			// 取消订单
			cancelOrder(id) {
				console.log(id)
				let params = {}
				params.id = id
				cancelOrder(params).then((res) => {
					console.log(res.code)
					if (res.code == 200) {
						Toast.success(res.msg);
						setTimeout(() => {
							this.$router.push({
								path: '/h5/orderPage'
							})
						}, 1000)
					} else {
						Toast.success(res.msg);
						setTimeout(() => {
							this.$router.push({
								path: '/h5/orderPage'
							})
						}, 1000)
					}
				})
			},






		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.my-orders-detail {
		// padding: 15px;
		// padding-bottom: 50px;
		background-color: #f6f6f6;

		.headerTop {
			width: 100%;
			height: 2.5rem;
			background-color: #FC6228;
		}

		.order-content {
			padding: 15px;
			// margin-top: 15px;
			// background-color: #FFFFFF;
			// box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
			border-radius: 5px;
			position: relative;
			top: -2.3rem;

			.orderInfo {
				width: 100%;
				box-sizing: border-box;
				// height: 2.3rem;
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				

			.goods-item {
				display: flex;
				background-color: #FFFFFF;
				margin-bottom: 5px;
				padding: 5px;
				border-radius: 5px;
			padding:10px;
				img {
					width: 80px;
					height: 80px;
					border-radius: 5px;
					margin-right: 15px;
				}
			
				.detailTitle {
					font-size: 14px;
				}
			
				.countUnit {
					font-size: 0.18rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
			
					p {
						margin: 0;
					}
				}
			
				.useTime {
					font-size: 0.2rem;
					color: red;
			
					span {
						color: #000;
					}
				}
			}
			}

			.numOrder {
				width: 100%;
				box-sizing: border-box;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				padding: 15px;

				.infoNum {
					font-size: 14px;
					color: #333333;
					font-weight: 500;
				}

				.listInfo {
					margin-left: 0.2rem;

					p {
						margin: 0;
						font-size: 12px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}

			.clientInfo {
				width: 100%;
				box-sizing: border-box;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				padding: 15px;

				.infoNum {
					font-size: 14px;
					font-weight: 500;
					color: #333333;
				}

				.listInfo {
					margin-left: 0.2rem;

					p {
						margin: 0;
						font-size: 12px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}

			.noticeInfo {
				width: 100%;
				box-sizing: border-box;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 50px;
				font-size: 0.2rem;
				padding: 15px;
				font-size: 0.22rem;
				font-weight: 500;
.infoNum{
	font-size: 14px;
	color: #333333;
}
				.fkm {
					margin-top: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					// flex-direction: column;
					#qrcode{
						width: 130px;
						height: 130px;
					}
				}
			}


		}

		.footerBott {
			width: 100%;
			height: 51px;
			background: #FFFFFF;
			position: fixed;
			bottom: 0;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			button {
				width: 87px;
				height: 34px;
				background: #FC6228;
				border-radius: 5px;
				border: none;
				color: #FFFFFF;
				margin-right: 10px;
			}
		}
	}
</style>
